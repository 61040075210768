<template>
	<div>
		<!-- Title and Logo -->
        <HeadTitle />
        <!-- end Logo and Title -->

        <!-- Panel start here -->
		<panel title="Edit User" class="panel panel-danger">
            <!-- <template slot="header">
                <h6 class="font-weight-bold mr-auto"> FORM INPUT BENTUK BADAN</h6>
            </template> -->
            <div class="pl-4 py-2 border rounded shadow-sm">
				<b-button variant="primary" class="px-4" to="/admin/management/users/list" pill>
                    <i class="fa fa-arrow-circle-left pr-2"></i> 
                    Kembali
                </b-button>
			</div>
			<div class="py-3">
                <b-card class="shadow-sm">
                        <b-form @submit.prevent="update" @keydown="form.onKeydown($event)" autocomplete="off">
                            <!-- <input type="hidden" name="_token" :value="csrf"> -->
                            <b-alert v-if="message" variant="danger" show>Data yang anda input tidak valid.</b-alert>
                        <b-table-simple striped>
                                <b-tbody>
                                    <b-tr>
                                        <b-td class="font-weight-bold" width="300px">Nama <span class="text-danger">*</span></b-td>
                                        <b-td>
                                            <b-row>
                                                <b-col md="10">
                                                    <input type="text" :class="{ 'form-control': true, 'is-invalid': form.errors.has('name')}" v-model="form.name" placeholder="Masukkan Nama" />
                                                </b-col>
                                            </b-row>
                                            <em v-if="form.errors.has('name')" class="form-text text-danger">{{errors.name[0]}}</em>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td class="font-weight-bold" width="300px">Email <span class="text-danger">*</span></b-td>
                                        <b-td>
                                            <b-row>
                                                <b-col md="10">
                                                    <input type="text" :class="{ 'form-control': true, 'is-invalid': form.errors.has('email')}" v-model="form.email" placeholder="Masukkan Email" />
                                                </b-col>
                                            </b-row>
                                            <em v-if="form.errors.has('email')" class="form-text text-danger">{{errors.email[0]}}</em>
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-td class="font-weight-bold" width="300px">Password <span class="text-danger">*</span></b-td>
                                        <b-td>
                                            <b-row>
                                                <b-col md="10">
                                                    <input type="password" :class="{ 'form-control': true, 'is-invalid': form.errors.has('password')}" v-model="form.password" placeholder="Masukkan Password" />
                                                </b-col>
                                            </b-row>
                                            <em v-if="form.errors.has('password')" class="form-text text-danger">{{errors.password[0]}}</em>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        <hr>
                        <div class="float-right">
                            <span>
                                <b-button variant="primary" type="submit" squared class="px-4" pill><i class="fa fa-save"></i> Simpan</b-button>
                            </span>
                        </div>
                    </b-form>
                </b-card>
            </div>
        </panel>
        <!-- Panel end here -->
	</div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import Logo from '@/assets/images/logoSumut.png'
import HeadTitle from '@/components/header/HRetribusi.vue'
import axios from 'axios'

export default {
    components: {
        HeadTitle
    },
    data () {
        return {
            imageLogo: Logo,
            form: new Form({
                name: '',
                email: '',
                password: ''
            }),

            /* // variabel form rekening
            rekening: {
                kodeNama: '',
                kodeRekening: '0'
            }, */
            errors: [],
            message: null,
            userDetail: [],
            // csrf token
            // csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
    },
    mounted () {
        this.getUserDetail()
    },
    methods: {
        // update data
        update () {
            this.form.put('/api/manajemen/user/' + this.$route.params.id).then(response => {
                if (this.form.successful) {
                    this.$swal({
                        icon: 'success',
                        title: 'User berhasil diupdate',
                        type: 'success',
                        confirmButtonClass: 'btn btn-info',
                        showConfirmButton: false,
                        showCloseButton: true,
                        timer: 1500
                    })
                    this.$router.push({name: 'ListUsers'})
                    /*  this.form.reset()
                    this.kodeNama= ''
                    this.message = false */
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors
                    this.message = error.response.data.message
                } else if (error.response.status === 401) {
                    localStorage.removeItem("EP_token");
                    this.$router.push({name: 'Login'})
                }
                console.log(error)
            })
        },

        // get user detail
        getUserDetail () {
            axios.get('/api/manajemen/user/' + this.$route.params.id).then(response => {
                this.userDetail = response.data.data
                this.form.fill(this.userDetail)
                /* console.log(this.userDetail) */
            }).catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.removeItem("EP_token");
                    this.$router.push({name: 'Login'})
                }
            })
        },
        // reset
        reset () {
            this.form.reset()
        }
    }
}
</script>